<template>
  <div class="jsonList">
    <div class="jsonList-item" v-for="(item, index) in own_value" :key="index">
      <el-input
        :class="{ 'jsonList-inputMsg': item.keyErr }"
        class="jsonList-item__key"
        v-model="item.key"
        @input="e => changeData(e, item, 'key')"
        :placeholder="$t('config.strategy.env_var.key_placeholder')"
      ></el-input>
      <span class="jsonList-item__middle">=</span>
      <el-input
        :class="{ 'jsonList-inputMsg': item.valueErr }"
        class="jsonList-item__value"
        v-model="item.value"
        @input="e => changeData(e, item, 'value')"
        :placeholder="$t('config.strategy.env_var.value_placeholder')"
      ></el-input>
      <!-- <img
        v-if="index === 0"
        @click="add"
        class="jsonList-item__add"
        src="@/assets/img/detection/add.svg"
      /> -->
      <svg-icon name="jia" class="jsonList-item__add" v-if="index === 0" @click="add"></svg-icon>
      <svg-icon name="jian" class="jsonList-item__reduce" v-else @click="reduce(index)"></svg-icon>
      <!-- <img
        v-else
        @click="reduce(index)"
        class="jsonList-item__reduce"
        src="@/assets/img/detection/reduce.svg"
      /> -->
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, defineProps, defineEmits } from 'vue'

const props = defineProps({
  value: {
    type: Array,
    default: () => []
  }
})
// const { value } = toRefs(props)
const value = JSON.parse(JSON.stringify(props.value))
const own_value = ref(value ? value.map(item => {
  return {
    key: item.split('=')[0],
    value: item.split('=')[1]
  }
}) : [
  {
    key: '',
    value: ''
  }
])

const emit = defineEmits(['inputError', 'change', 'update:value'])

const obj = [{
  key: '',
  value: ''
}]

watch(() => props.value, (a, b) => {
  own_value.value = a.value || obj
  reset()
})

onMounted(() => reset())

function reset() {
  if (own_value.value.length === 0) {
    own_value.value.push({
      key: '',
      value: ''
    })
  }
}
function add() {
  own_value.value.push({
    key: '',
    value: ''
  })
}
function reduce(index) {
  own_value.value.splice(index, 1)
  changeData()
}
function changeData(data, item, type) {
  // 输入框错误检测
  if (item) {
        item[`${type}Err`] = /[^\x00-\xff]/.test(data) // eslint-disable-line
  }
  emit(
    'inputError',
    own_value.value.some((item) => item.keyErr || item.valueErr)
  )
  emit('update:value', own_value.value)
  emit('change', own_value.value)
}

</script>
<style lang="scss" scoped>
[data-theme=light]{
  .jsonList-item__add, .jsonList-item__reduce {
    filter: invert(1);
    &:hover  {
      filter: brightness(50%);
    }
  }
}

.jsonList  {
  &-inputMsg  {
    position: relative;
    &:after  {
      content: '\8bf7\8f93\5165\6b63\786e\7684\5b57\7b26\4e32';
      position: absolute;
      left: 5px;
      top: 27px;
      font-size: 12px;
      color: #F06D67;
    }
  }
  &-item  {
    & :deep(.el-input__inner){
      height: 32px;
      padding: 0 12px;
      width: 100%;
      border-color: var(--color-text-3);
      background-color: var( --detect-config-item-default);
      color: var(--color-text-1);
      &::placeholder  {
        color:var(--color-text-3);
      }
    }

    margin-bottom: 16px;
    &:last-child  {
      margin-bottom: 0;
    }
    &__key  {

      width: calc(36% - 24px);
    }
    &__middle  {
      margin: 0 8px;
      width: 8px;
    }
    &__value  {

      width: calc(64% - 36px);
    }
    &__add, &__reduce  {
      font-size: 30px;
      position: relative;
      top: 7px;
      left: 6px;
      cursor: pointer;
      &:hover  {
        filter: brightness(140%);
      }
    }
  }
}
</style>
