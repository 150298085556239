import { t } from 'app/i18n'
export const configPermise = {
  allClose: {
    // 操作系统
    system: {
      disable: true,
      option: ['Windows;7 SP1 Pro;32']
    },
    // 系统语言
    lang: {
      disable: true,
      option: ['zh-cn']
    },
    // 应用环境
    software_major: {
      disable: true
    },
    // 时区
    timezone: {
      disable: true
    },
    // 所在地区
    geo: {
      disable: true
    },
    // 系统日期与时间
    datetime: {
      disable: true
    },
    // 环境变量
    env_var: {
      disable: true
    },

    // 文件类型
    custom_detect_type: {
      disable: true
    },
    // 命令行
    cmd: {
      disable: true
    },
    // 解压密码
    pwd_to_zip: {
      disable: true
    },
    // 指定压缩文件的子文件
    in_zip: {
      disable: true
    },
    // 指定应用打开文件
    office: {
      disable: true
    },
    // 邮件文件的处理方式
    emailer: {
      disable: false
    },
    // 文档密码
    pwd_to_mso: {
      disable: true
    },
    // DLL 参数传入 函数名
    function: {
      disable: true
    },
    // DLL 参数传入 参数
    arguments: {
      disable: true
    },
    // 自定义文件名
    name_forced: {
      disable: true
    },
    // 自定义扩展名
    ext_forced: {
      disable: true
    },
    // 指定目录下执行进程
    runpath: {
      disable: true
    },
    // url配置radio
    urlObjectConfig: {
      disable: true
    },
    // 在浏览器中打开
    browser: {
      disable: true
    },
    // user_agent
    user_agent: {
      disable: true
    },

    // 分析时长
    timeout: {
      disable: true,
      max: 120
    },
    // 模拟点击
    human: {
      disable: false
    },
    // 自动点击
    human_popbox: {
      disable: false
    },
    // 自动滚动
    human_scroll: {
      disable: false
    },
    // 剪贴板填充
    human_clipbd: {
      disable: false
    },
    // 鼠标移动
    human_curpos: {
      disable: false
    },
    // 自动安装
    human_autoin: {
      disable: true
    },
    // 快速动态检测
    fast_detect: {
      disable: false
    },
    // 模拟重启
    fake_reboot: {
      disable: false
    },
    // 主动重启系统
    active_reboot: {
      disable: true
    },
    // 暴力破解密码
    pwd_forced: {
      disable: false
    },

    // 链接互联网
    internet: {
      disable: true
    },
    // fakeNet
    fake_net: {
      disable: true
    },
    // https流量
    decrypt_https: {
      disable: true
    },
    // 互联网出口设置
    exitLocation: {
      disable: true
    },
    // 网络流量路由方式
    useNetCheckbox: {
      disable: true
    },
    // tor vpn proxy 的radio
    netRadio: {
      disable: true
    },
    // tor
    tor: {
      disable: true
    },
    // vpn
    vpn: {
      disable: true
    },
    // proxy
    proxy: {
      disable: true
    },
    // 代理服务器要求密码
    userRequiredCheckbox: {
      disable: true
    },
    // 用户名
    username: {
      disable: true
    },
    // 密码
    password: {
      disable: true
    }
  },
  visitor: {
    // 默认选中强制快速检测
    default_quick_detect: {
      disable: true,
      strategy: 1
    },
    // 操作系统
    system: {
      disable: false,
      option: ['Windows;7 SP1 Pro;32', 'Android;11;64']
    },
    // 系统语言
    lang: {
      disable: false,
      option: ['zh-cn']
    },
    // 应用环境
    software_major: {
      disable: false
    },
    // 时区
    timezone: {
      disable: true
    },
    // 所在地区
    geo: {
      disable: true
    },
    // 系统日期与时间
    datetime: {
      disable: true
    },
    // 环境变量
    env_var: {
      disable: true
    },

    // 文件类型
    custom_detect_type: {
      disable: false
    },
    // 命令行
    cmd: {
      disable: false
    },
    // 解压密码
    pwd_to_zip: {
      disable: true
    },
    // 指定压缩文件的子文件
    in_zip: {
      disable: true
    },
    // 指定应用打开文件
    office: {
      disable: true
    },
    // 邮件文件的处理方式
    emailer: {
      disable: false
    },
    // 文档密码
    pwd_to_mso: {
      disable: true
    },
    // DLL 参数传入 函数名
    function: {
      disable: true
    },
    // DLL 参数传入 参数
    arguments: {
      disable: true
    },
    // 自定义文件名
    name_forced: {
      disable: false
    },
    // 自定义扩展名
    ext_forced: {
      disable: false
    },
    // 指定目录下执行进程
    runpath: {
      disable: false
    },
    // url配置radio
    urlObjectConfig: {
      disable: false
    },
    // 在浏览器中打开
    browser: {
      disable: true
    },
    // user_agent
    user_agent: {
      disable: false
    },

    // 分析时长
    timeout: {
      disable: false,
      max: 120
    },
    // 模拟点击
    human: {
      disable: false
    },
    // 自动点击
    human_popbox: {
      disable: false
    },
    // 自动滚动
    human_scroll: {
      disable: false
    },
    // 剪贴板填充
    human_clipbd: {
      disable: false
    },
    // 鼠标移动
    human_curpos: {
      disable: false
    },
    // 自动安装
    human_autoin: {
      disable: true
    },
    // 快速动态检测
    fast_detect: {
      disable: false
    },
    // 模拟重启
    fake_reboot: {
      disable: false
    },
    // 主动重启系统
    active_reboot: {
      disable: true
    },
    // 暴力破解密码
    pwd_forced: {
      disable: false
    },

    // 链接互联网
    internet: {
      disable: false
    },
    // fakeNet
    fake_net: {
      disable: false
    },
    // https流量
    decrypt_https: {
      disable: true
    },
    // 互联网出口设置
    exitLocation: {
      disable: true
    },
    // 网络流量路由方式
    useNetCheckbox: {
      disable: false
    },
    // tor vpn proxy 的radio
    netRadio: {
      disable: false
    },
    // tor
    tor: {
      disable: false
    },
    // vpn
    vpn: {
      disable: true
    },
    // proxy
    proxy: {
      disable: true
    },
    // 代理服务器要求密码
    userRequiredCheckbox: {
      disable: true
    },
    // 用户名
    username: {
      disable: true
    },
    // 密码
    password: {
      disable: true
    }
  },
  free: {
    // 默认选中强制快速检测
    default_quick_detect: {
      disable: true,
      strategy: 1
    },
    // 操作系统
    system: {
      disable: false,
      option: ['Windows;7 SP1 Pro;32', 'Android;11;64']
    },
    // 系统语言
    lang: {
      disable: false,
      option: ['zh-cn']
    },
    // 应用环境
    software_major: {
      disable: false
    },
    // 时区
    timezone: {
      disable: true
    },
    // 所在地区
    geo: {
      disable: true
    },
    // 系统日期与时间
    datetime: {
      disable: true
    },
    // 环境变量
    env_var: {
      disable: true
    },

    // 文件类型
    custom_detect_type: {
      disable: false
    },
    // 命令行
    cmd: {
      disable: false
    },
    // 解压密码
    pwd_to_zip: {
      disable: true
    },
    // 指定压缩文件的子文件
    in_zip: {
      disable: true
    },
    // 指定应用打开文件
    office: {
      disable: true
    },
    // 邮件文件的处理方式
    emailer: {
      disable: false
    },
    // 文档密码
    pwd_to_mso: {
      disable: true
    },
    // DLL 参数传入 函数名
    function: {
      disable: true
    },
    // DLL 参数传入 参数
    arguments: {
      disable: true
    },
    // 自定义文件名
    name_forced: {
      disable: false
    },
    // 自定义扩展名
    ext_forced: {
      disable: false
    },
    // 指定目录下执行进程
    runpath: {
      disable: false
    },
    // url配置radio
    urlObjectConfig: {
      disable: false
    },
    // 在浏览器中打开
    browser: {
      disable: true
    },
    // user_agent
    user_agent: {
      disable: false
    },

    // 分析时长
    timeout: {
      disable: false,
      max: 120
    },
    // 模拟点击
    human: {
      disable: false
    },
    // 自动点击
    human_popbox: {
      disable: false
    },
    // 自动滚动
    human_scroll: {
      disable: false
    },
    // 剪贴板填充
    human_clipbd: {
      disable: false
    },
    // 鼠标移动
    human_curpos: {
      disable: false
    },
    // 自动安装
    human_autoin: {
      disable: true
    },
    // 快速动态检测
    fast_detect: {
      disable: false
    },
    // 模拟重启
    fake_reboot: {
      disable: false
    },
    // 主动重启系统
    active_reboot: {
      disable: true
    },
    // 暴力破解密码
    pwd_forced: {
      disable: false
    },

    // 链接互联网
    internet: {
      disable: false
    },
    // fakeNet
    fake_net: {
      disable: false
    },
    // https流量
    decrypt_https: {
      disable: true
    },
    // 互联网出口设置
    exitLocation: {
      disable: true
    },
    // 网络流量路由方式
    useNetCheckbox: {
      disable: false
    },
    // tor vpn proxy 的radio
    netRadio: {
      disable: false
    },
    // tor
    tor: {
      disable: false
    },
    // vpn
    vpn: {
      disable: true
    },
    // proxy
    proxy: {
      disable: true
    },
    // 代理服务器要求密码
    userRequiredCheckbox: {
      disable: true
    },
    // 用户名
    username: {
      disable: true
    },
    // 密码
    password: {
      disable: true
    }
  },
  professional: {
    default_quick_detect: {
      disable: false,
      strategy: 2
    },
    // 操作系统
    system: {
      disable: false,
      option: ['Windows;7 SP1 Pro;64', 'Windows;7 SP1 Pro;32', 'Android;11;64']
    },
    // 系统语言
    lang: {
      disable: false,
      option: ['zh-cn', 'en-us']
    },
    // 应用环境
    software_major: {
      disable: false
    },
    // 时区
    timezone: {
      disable: true
    },
    // 所在地区
    geo: {
      disable: true
    },
    // 系统日期与时间
    datetime: {
      disable: true
    },
    // 环境变量
    env_var: {
      disable: true
    },

    // 文件类型
    custom_detect_type: {
      disable: false
    },
    // 命令行
    cmd: {
      disable: false
    },
    // 解压密码
    pwd_to_zip: {
      disable: false
    },
    // 指定压缩文件的子文件
    in_zip: {
      disable: false
    },
    // 指定应用打开文件
    office: {
      disable: false
    },
    // 邮件文件的处理方式
    emailer: {
      disable: false
    },
    // 文档密码
    pwd_to_mso: {
      disable: false
    },
    // DLL 参数传入 函数名
    function: {
      disable: true
    },
    // DLL 参数传入 参数
    arguments: {
      disable: false
    },
    // 自定义文件名
    name_forced: {
      disable: false
    },
    // 自定义扩展名
    ext_forced: {
      disable: false
    },
    // 指定目录下执行进程
    runpath: {
      disable: false
    },
    // url配置radio
    urlObjectConfig: {
      disable: false
    },
    // 在浏览器中打开
    browser: {
      disable: false
    },
    // user_agent
    user_agent: {
      disable: false
    },

    // 分析时长
    timeout: {
      disable: false,
      max: 360
    },
    // 模拟点击
    human: {
      disable: false
    },
    // 自动点击
    human_popbox: {
      disable: false
    },
    // 自动滚动
    human_scroll: {
      disable: false
    },
    // 剪贴板填充
    human_clipbd: {
      disable: false
    },
    // 鼠标移动
    human_curpos: {
      disable: false
    },
    // 自动安装
    human_autoin: {
      disable: false
    },
    // 快速动态检测
    fast_detect: {
      disable: false
    },
    // 模拟重启
    fake_reboot: {
      disable: false
    },
    // 主动重启系统
    active_reboot: {
      disable: true
    },
    // 暴力破解密码
    pwd_forced: {
      disable: false
    },

    // 链接互联网
    internet: {
      disable: false
    },
    // fakeNet
    fake_net: {
      disable: false
    },
    // https流量
    decrypt_https: {
      disable: false
    },
    // 互联网出口设置
    exitLocation: {
      disable: true
    },
    // 网络流量路由方式
    useNetCheckbox: {
      disable: false
    },
    // tor vpn proxy 的radio
    netRadio: {
      disable: false
    },
    // tor
    tor: {
      disable: false
    },
    // vpn
    vpn: {
      disable: true
    },
    // proxy
    proxy: {
      disable: true
    },
    // 代理服务器要求密码
    userRequiredCheckbox: {
      disable: true
    },
    // 用户名
    username: {
      disable: true
    },
    // 密码
    password: {
      disable: true
    }
  },
  enterprise: {
    default_quick_detect: {
      disable: false,
      strategy: 2
    },
    // 操作系统
    system: {
      disable: false,
      option: [
        'Windows;7 SP1 Pro;64',
        'Windows;7 SP1 Pro;32',
        'Windows;8.1 Update 1 Ent;64',
        'Windows;8.1 Update 1 Ent;32',
        'Windows;10 Ent LTSB 2016;64',
        'Windows;10 Ent LTSB 2016;32',
        'Ubuntu;18.04;64',
        'Android;11;64'
      ]
    },
    // 系统语言
    lang: {
      disable: false,
      option: ['zh-cn', 'en-us']
    },
    // 应用环境
    software_major: {
      disable: false
    },
    // 时区
    timezone: {
      disable: false
    },
    // 所在地区
    geo: {
      disable: false
    },
    // 系统日期与时间
    datetime: {
      disable: false
    },
    // 环境变量
    env_var: {
      disable: false
    },

    // 文件类型
    custom_detect_type: {
      disable: false
    },
    // 命令行
    cmd: {
      disable: false
    },
    // 解压密码
    pwd_to_zip: {
      disable: false
    },
    // 指定压缩文件的子文件
    in_zip: {
      disable: false
    },
    // 指定应用打开文件
    office: {
      disable: false
    },
    // 邮件文件的处理方式
    emailer: {
      disable: false
    },
    // 文档密码
    pwd_to_mso: {
      disable: false
    },
    // DLL 参数传入 函数名
    function: {
      disable: false
    },
    // DLL 参数传入 参数
    arguments: {
      disable: false
    },
    // 自定义文件名
    name_forced: {
      disable: false
    },
    // 自定义扩展名
    ext_forced: {
      disable: false
    },
    // 指定目录下执行进程
    runpath: {
      disable: false
    },
    // url配置radio
    urlObjectConfig: {
      disable: false
    },
    // 在浏览器中打开
    browser: {
      disable: false
    },
    // user_agent
    user_agent: {
      disable: false
    },

    // 分析时长
    timeout: {
      disable: false,
      max: 660
    },
    // 模拟点击
    human: {
      disable: false
    },
    // 主动重启系统
    // 自动点击
    human_popbox: {
      disable: false
    },
    // 自动滚动
    human_scroll: {
      disable: false
    },
    // 剪贴板填充
    human_clipbd: {
      disable: false
    },
    // 鼠标移动
    human_curpos: {
      disable: false
    },
    // 自动安装
    human_autoin: {
      disable: false
    },
    // 快速动态检测
    fast_detect: {
      disable: false
    },
    // 模拟重启
    fake_reboot: {
      disable: false
    },
    // 主动重启系统
    active_reboot: {
      disable: false
    },
    // 暴力破解密码
    pwd_forced: {
      disable: false
    },

    // 链接互联网
    internet: {
      disable: false
    },
    // fakeNet
    fake_net: {
      disable: false
    },
    // https流量
    decrypt_https: {
      disable: false
    },
    // 互联网出口设置
    exitLocation: {
      disable: false
    },
    // 网络流量路由方式
    useNetCheckbox: {
      disable: false
    },
    // tor vpn proxy 的radio
    netRadio: {
      disable: false
    },
    // tor
    tor: {
      disable: false
    },
    // vpn
    vpn: {
      disable: false
    },
    // proxy
    proxy: {
      disable: false
    },
    // 代理服务器要求密码
    userRequiredCheckbox: {
      disable: false
    },
    // 用户名
    username: {
      disable: false
    },
    // 密码
    password: {
      disable: false
    }
  },
  qihoo: {
    default_quick_detect: {
      disable: false,
      strategy: 2
    },
    // 操作系统
    system: {
      disable: false,
      option: [
        'Windows;7 SP1 Pro;64',
        'Windows;7 SP1 Pro;32',
        'Windows;8.1 Update 1 Ent;64',
        'Windows;8.1 Update 1 Ent;32',
        'Windows;10 Ent LTSB 2016;64',
        'Windows;10 Ent LTSB 2016;32',
        'Ubuntu;18.04;64',
        'Android;11;64'
      ]
    },
    // 系统语言
    lang: {
      disable: false,
      option: ['zh-cn', 'en-us']
    },
    // 应用环境
    software_major: {
      disable: false
    },
    // 时区
    timezone: {
      disable: false
    },
    // 所在地区
    geo: {
      disable: false
    },
    // 系统日期与时间
    datetime: {
      disable: false
    },
    // 环境变量
    env_var: {
      disable: false
    },

    // 文件类型
    custom_detect_type: {
      disable: false
    },
    // 命令行
    cmd: {
      disable: false
    },
    // 解压密码
    pwd_to_zip: {
      disable: false
    },
    // 指定压缩文件的子文件
    in_zip: {
      disable: false
    },
    // 指定应用打开文件
    office: {
      disable: false
    },
    // 邮件文件的处理方式
    emailer: {
      disable: false
    },
    // 文档密码
    pwd_to_mso: {
      disable: false
    },
    // DLL 参数传入 函数名
    function: {
      disable: false
    },
    // DLL 参数传入 参数
    arguments: {
      disable: false
    },
    // 自定义文件名
    name_forced: {
      disable: false
    },
    // 自定义扩展名
    ext_forced: {
      disable: false
    },
    // 指定目录下执行进程
    runpath: {
      disable: false
    },
    // url配置radio
    urlObjectConfig: {
      disable: false
    },
    // 在浏览器中打开
    browser: {
      disable: false
    },
    // user_agent
    user_agent: {
      disable: false
    },

    // 分析时长
    timeout: {
      disable: false,
      max: 660
    },
    // 模拟点击
    human: {
      disable: false
    },
    // 主动重启系统
    // 自动点击
    human_popbox: {
      disable: false
    },
    // 自动滚动
    human_scroll: {
      disable: false
    },
    // 剪贴板填充
    human_clipbd: {
      disable: false
    },
    // 鼠标移动
    human_curpos: {
      disable: false
    },
    // 自动安装
    human_autoin: {
      disable: false
    },
    // 快速动态检测
    fast_detect: {
      disable: false
    },
    // 模拟重启
    fake_reboot: {
      disable: false
    },
    // 主动重启系统
    active_reboot: {
      disable: false
    },
    // 暴力破解密码
    pwd_forced: {
      disable: false
    },
    // 链接互联网
    internet: {
      disable: false
    },
    // fakeNet
    fake_net: {
      disable: false
    },
    // https流量
    decrypt_https: {
      disable: false
    },
    // 互联网出口设置
    exitLocation: {
      disable: false
    },
    // 网络流量路由方式
    useNetCheckbox: {
      disable: false
    },
    // tor vpn proxy 的radio
    netRadio: {
      disable: false
    },
    // tor
    tor: {
      disable: false
    },
    // vpn
    vpn: {
      disable: false
    },
    // proxy
    proxy: {
      disable: false
    },
    // 代理服务器要求密码
    userRequiredCheckbox: {
      disable: false
    },
    // 用户名
    username: {
      disable: false
    },
    // 密码
    password: {
      disable: false
    }
  }
}

export const detectionPermise = {
  visitor: {
    havePrivate: false,
    haveHash: false,
    fileSize: {
      value: 20 * 1024 * 1024,
      msg: t('other.views.permise.filesize')
    },
    // 每次个数
    once: {
      value: 1,
      msg: t('other.views.permise.filenumber')
    },
    // 每分钟次数
    minTimes: {
      value: 4,
      msg: t('other.views.permise.secondnumber')
    },
    dayTimes: {
      value: 100,
      msg: t('other.views.permise.dayTimes')
    },
    monthTimes: {
      value: 3000,
      msg: t('other.views.permise.monthTimes')
    }
  },
  free: {
    havePrivate: false,
    haveHash: false,
    fileSize: {
      value: 20 * 1024 * 1024,
      msg: t('other.views.permise.filesize')
    },
    // 每次个数
    once: {
      value: 1,
      msg: t('other.views.permise.filenumber')
    },
    // 每分钟次数
    minTimes: {
      value: 10,
      msg: t('other.views.permise.secondnumber')
    },
    dayTimes: {
      value: 100,
      msg: t('other.views.permise.dayTimes')
    },
    monthTimes: {
      value: 3000,
      msg: t('other.views.permise.monthTimes')
    }
  },
  professional: {
    havePrivate: true,
    haveHash: false,
    fileSize: {
      value: 50 * 1024 * 1024,
      msg: t('other.views.permise.filesize2')
    },
    once: {
      value: 20,
      msg: t('other.views.permise.filenumber2')
    },
    minTimes: {
      value: 30,
      msg: t('other.views.permise.secondnumber2')
    },
    dayTimes: {
      value: 500,
      msg: t('other.views.permise.dayTimes2')
    },
    monthTimes: {
      value: 15000,
      msg: t('other.views.permise.monthTimes2')
    }
  },
  enterprise: {
    havePrivate: true,
    haveHash: true,
    fileSize: {
      value: 100 * 1024 * 1024,
      msg: t('other.views.permise.filesize3')
    },
    once: {
      value: 40,
      msg: t('other.views.permise.filenumber3')
    },
    minTimes: {
      value: 60,
      msg: t('other.views.permise.secondnumber3')
    },
    dayTimes: {
      value: 5000,
      msg: t('other.views.permise.dayTimes3')
    },
    monthTimes: {
      value: 150000,
      msg: t('other.views.permise.monthTimes3')
    }
  },
  qihoo: {
    havePrivate: true,
    haveHash: true,
    fileSize: {
      value: 104857600,
      msg: t('other.views.permise.filesize3')
    },
    once: {
      value: 500,
      msg: t('other.views.permise.filenumber3')
    },
    minTimes: {
      value: 600,
      msg: t('other.views.permise.secondnumber3')
    },
    dayTimes: {
      value: 2000000,
      msg: t('other.views.permise.dayTimes3')
    },
    monthTimes: {
      value: 6000000,
      msg: t('other.views.permise.monthTimes3')
    }
  }
}

export const permiseLevel = {
  visitor: [1, 0],
  free: [1, 0],
  professional: [1, 2, 0],
  enterprise: [1, 2, 3, 0],
  qihoo: [1, 2, 3, 4, 0]
}

export const fileType = ['7z', 'dll', 'doc', 'eml', 'exe', 'html', 'hwp', 'js', 'mht', 'msg', 'pdf', 'ppt', 'ps1', 'py', 'rar', 'rtf', 'swf', 'vbs', 'wsf', 'xls', 'zip']

export const uploadStatus = {
  101: t('other.views.permise.uploadStatus1'),
  102: t('other.views.permise.uploadStatus2'),
  103: t('other.views.permise.uploadStatus3'),
  104: t('other.views.permise.uploadStatus4'),
  105: t('other.views.permise.uploadStatus5'),
  106: t('other.views.permise.uploadStatus6'),
  107: t('other.views.permise.uploadStatus7'),
  108: t('other.views.permise.uploadStatus8'),
  109: t('other.views.permise.uploadStatus9'),
  110: t('other.views.permise.uploadStatus10'),
  111: t('other.views.permise.uploadStatus11'),
  112: t('other.views.permise.uploadStatus12'),
  113: t('other.views.permise.uploadStatus13'),
  114: t('other.views.permise.uploadStatus14'),
  115: t('other.views.permise.uploadStatus15'),
  116: t('other.views.permise.uploadStatus16'),
  140: t('other.views.permise.uploadStatus17'),
  150: t('other.views.permise.uploadStatus18'),
  401: t('other.views.permise.uploadStatus19')
}
