import request from 'app/http'

const getTaskConfig = (params) => {
  return request.get('/task/config', params)
}

const postTaskSubmitSingle = (params, token) => {
  const url = token ? `/task/submit/detect?_token=${token}` : '/task/submit/detect'
  return request({
    method: 'post',
    url,
    data: params,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  })
}

const reSubmit = (params) => {
  return request({
    method: 'post',
    url: '/task/resubmit',
    data: params,
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    }
  })
}

const getImages = (params) => {
  return request.get('/images/images', { params })
}
const getImagesFiletype = (params) => {
  return request.get('/task/filetypes', params)
}
const getImagesTimezone = (params) => {
  return request.get('/images/timezone', { params })
}
const getImagesRegion = (params) => {
  return request.get('/images/region', { params })
}
const getImagesWkdir = (params) => {
  return request.get('/images/wkdir', params)
}
const getUrlState = (params) => {
  return request.post('/task/validate-url', params)
}

const getConstants = (params) => {
  return request.get('/task/constants', params)
}

const postUploadSample = (params, onUploadProgress) => {
  return request({
    method: 'post',
    url: '/task/upload_sample',
    data: params,
    headers: {
      'Content-type': 'multipart/form-data'
    },
    onUploadProgress
  })
}
const checkUploadSample = (params) => {
  return request.get('/task/check_sample', { params })
}
export {
  getTaskConfig,
  postTaskSubmitSingle,
  getImages,
  getImagesFiletype,
  getImagesTimezone,
  getImagesRegion,
  getImagesWkdir,
  getUrlState,
  getConstants,
  postUploadSample,
  checkUploadSample,
  reSubmit
}
