<template>
  <el-dialog
    width="348px"
    v-model="visible"
    @close="close"
    :close-on-click-modal="false"
    :title="$t('account_manager.edit_user.safety_verification')"
    custom-class="captch-dialog"
    :append-to-body="true"
  >
  <div class="dialog-content__wrap" id="default-captcha">
  </div>
  <div class="footer-content">
    {{ $t('other.components.report.captchText') }}
  </div>
  </el-dialog>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch, nextTick, onMounted } from 'vue'

const emit = defineEmits(['close'])
const visible = ref(false)

const props = defineProps({
  captchDialogVisible: {
    default: false,
    type: Boolean
  }
})

watch(() => props.captchDialogVisible, (val) => {
  visible.value = val
  if (val) {
    nextTick(() => {
      renderCaptch()
    })
  }
})

const close = (token) => {
  if (token) {
    emit('close')
  } else {
    emit('close', token)
  }
}

onMounted(() => {
  if (props.captchDialogVisible) {
    visible.value = true
    nextTick(() => {
      renderCaptch()
    })
  }
})

const renderCaptch = () => {
  console.log(window._jg)
  console.log(document.getElementById('default-captcha'))
  window._jg && window._jg.Captcha(document.getElementById('default-captcha'), {
    appId: 'aca5291eea10d0b7',
    phone: '',
    success: function (token) {
      // 验证成功的回调
      console.log(token)
      emit('close', token)
    },
    ready: function (captchaId) {
      // 验证码物料准备就绪的回调
      console.log(captchaId)
    },
    error: function (code) {
      // 验证失败的回调
      console.log(code)
    }
  })
}
</script>

<style lang="scss" scoped>
.dialog-content__wrap {
  height: 200px;
}
.footer-content {
  line-height: 40px;
  font-size: 12px;
  text-align: center;
  color: #8A9099;
}
</style>

<style lang="scss">
.captch-dialog {
  .el-dialog__body {
    padding-bottom: 10px;
    .jg_captcha_basic_content {
      /* height: 150px; */
      background: var(--color-bg-1);
    }
    // .jg_captcha_basic_bar-verifying {
    //   background: var(--color-bg-3);
    // }
    // .jg_captcha_basic_bar-inform {
    //   margin-top: -1px;
    //   background: var(--color-bg-3);
    // }
    // .jg_captcha_basic_bar-success {
    //   background: var(--color-bg-3);
    // }
    // .jg_captcha_basic_bar-fail {
    //   background: var(--color-bg-3);
    // }
    // .jg_captcha_basic_slider-cover {
    //   background: var(--color-bg-3);
    // }
    // .jg_captcha_basic_bar {
    //   background: var(--color-bg-3);
    //   border: none;
    // }
  }
}
</style>
