<template>
  <div class="publish">
    <el-dialog width="570px" v-model="visible" @close="close" :title="$t('other.components.report.agreePublic')">
      <div class="publish-content">
        <svg-icon name="info" color="#fa8c16"></svg-icon>
        <div style="line-height: 22px;">{{ $t('other.components.report.agreePublicText') }}</div>
      </div>
      <template #footer>
        <el-button @click="close" plain>{{ $t('dialog.cancel') }}</el-button>
        <el-button @click="ok" type="primary">{{ $t('dialog.confirm') }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps } from 'vue'

const props = defineProps({
  show: {
    type: Boolean,
    default: true
  }
})

const visible = ref(props.show)
const emits = defineEmits(['update:show', 'ok'])
const close = () => {
  // 配合v-model
  emits('update:show', false)
}

const ok = () => {
  emits('ok')
  close()
}
</script>

<style lang="scss" scoped>
.publish  {
  &-content  {
    display: flex;
    svg {
      margin-right: 8px;
      font-size: 20px;
      min-width: 20px;
      color:#fa8c16;
    }
  }
}
</style>
